<template lang="pug">
div
  font-dropdown(label="fontFamily" :property="getPath('fontFamily')" :i18n="false")
  align-select(
    v-if="align"
    label="textAlign"
    :options="direction.map(({ key }) => key)"
    :property="getPath('textAlign')"
    type="text"
  )
  om-dropdown-input(
    v-if="align"
    label="textAlign"
    :property="getPath('textAlign')"
    :items="direction"
    typeOfComponent="product"
    :editMobile="true"
  )
  .mb-3
    om-font-awesome-button(
      label="style"
      :subPath="subPath"
      :property="property"
      typeOfComponent="product"
      :strikeThrough="true"
      :editMobile="true"
    )
  om-range-input(
    :property="getPath('fontSize')"
    label="fontSize"
    typeOfComponent="product"
    :min="8"
    :max="90"
    :step="1"
    :editMobile="true"
  )
  om-color-input(
    label="color"
    :property="getPath('color')"
    typeOfComponent="product"
    :editMobile="true"
  )
</template>

<script>
  import AlignSelect from '@/editor/components/sidebar/components/AlignSelect.vue';
  import itemMixin from '../../../mixins/item';

  export default {
    components: { AlignSelect },
    mixins: [itemMixin],
    props: {
      property: { type: String, default: 'selectedElement.$device' },
      subPath: { type: String, default: '' },
      align: { type: Boolean, default: false },
      editMobile: { type: Boolean, default: true },
    },
    data: () => ({
      direction: [
        { key: 'left', value: 'left' },
        { key: 'center', value: 'center' },
        { key: 'right', value: 'right' },
      ],
    }),
  };
</script>
